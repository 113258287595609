<script>
import { mapActions, mapGetters } from "vuex";
import debounce from "debounce";

import tnVue from "@/mixins/tn.vue";

import TableCog from "@/components/table-cog.vue";
import TableFilter from "@/components/modals/price-list/table-filter.vue";
import TableResizeVue from "@/mixins/table-resize.vue";

import Add from "@/components/modals/my-discount/add.vue";
import PriceListProviderFavoriteBtn from "./price-list-provider-favorite-btn.vue";
import PriceListBlackBtn from "./price-list-black-btn.vue";
import PermissionConfirmDialog from "@/components/modals/permission-confirm-dialog.vue";

export default {
  components: {
    Add,
    TableFilter,
    TableCog,
    PriceListProviderFavoriteBtn,
    PriceListBlackBtn,
    PermissionConfirmDialog,
  },

  data: (vm) => ({
    ln: "price_list",
    uniqKey: "price-list",

    options: {},
    itemsPerPage: 50,

    loaders: {},

    selected: {},

    isAdd: false,
    isFilter: false,
    searchText: null,
    filterData: vm.$getFilterData() || {},
    filteredHeaders: [],
    isShowFavorite: undefined,
    isShowBlackList: undefined,

    isPermissionAlert: false,
    tryCount: null,
  }),

  mixins: [tnVue, TableResizeVue],

  computed: {
    ...mapGetters("auth", ["GET_USER", "GET_USER_PERMISSIONS"]),

    size: (vm) => vm.$store.getters.GET_SIZE,

    ...mapGetters("price-list", ["GET", "GET_COUNT"]),

    entries: (vm) => vm.GET,
    entriesCount: (vm) => vm.GET_COUNT,

    tableHeight: (vm) => vm.size.height - 170,

    tableHeaders: (vm) =>
      [
        {
          text: "№",
          value: "number",
          width: 10,
          sortable: false,
          _resize: false,
          class: "disable-resize",
        },
        {
          text: "#",
          value: "favoriteAndBlackList",
          width: 10,
          sortable: false,
          align: "center",
          _resize: false,
          class: "disable-resize",
        },
        {
          text: vm.tn("table.name"),
          value: "provider",
          _required: true,
        },
        {
          text: vm.tn("table.my_discount"),
          value: "discountPercent",
          width: 200,
          _filter: (v) => vm.$sum(v) || "",
        },
        {
          text: vm.tn("table.my_min_sum_purchase"),
          value: "discountMinAmountToPurchase",
          width: 200,
          _filter: (v) => vm.$sum(v) || "",
        },
        {
          text: vm.tn("table.my_min_sum_delivery"),
          value: "discountMinAmountToPurchaseWithDelivery",
          width: 200,
          _filter: (v) => vm.$sum(v) || "",
        },
        // {
        //   text: vm.tn("table.amount"),
        //   value: "minAmountToPurchase",
        //   width: 200,
        //   _filter: (v, i) => `${vm.$sum(i.minAmountToPurchase)} / ${vm.$sum(i.minAmountToPurchaseWithDelivery)}`,
        // },
        {
          text: vm.tn("table.updated"),
          value: "timePublished",
          width: 10,
          _filter: (v) => vm.$dateTime(v),
        },
        {
          text: vm.tn("table.expiryDate"),
          value: "expiryDate",
          width: 10,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("table.qty"),
          value: "itemsCount",
          width: 10,
          _filter: (v) => vm.$sum(v),
        },
        {
          text: vm.tn("table.phone"),
          value: "phone",
          width: 10,
          _html: true,
          _filter: (v) => {
            const array = `${v || ""}`.split(", ");
            let phone = vm.$firstArrayItem(array);
            return vm.$tel(phone);
          },
        },
        {
          text: "",
          value: "actions",
          width: 0,
          sortable: false,
          _resize: false,
          class: "disable-resize",
        },
      ].map((x) => {
        if (vm.tdSizes[x.value]) x.width = vm.tdSizes[x.value];
        return x;
      }),

    actionsList: (vm) => [
      {
        name: vm.tn("actions.show_price_btn"),
        action: (item) => window.open(`/price-list/${item.guid}`, "_blank"),
        icon: "mdi-information",
      },
      {
        name: vm.tn("actions.info_btn"),
        action: (item) => {
          vm.$windowOpen("price-list-provider", item.providerGuid, item.provider, 650, 800, "preview=1");
        },
        icon: "mdi-information",
      },
    ],

    favoriteBtnTitle() {
      if (this.isShowFavorite) {
        return this.tn("favorite.show_not_favorite_btn");
      } else if (this.isShowFavorite == false) {
        return this.tn("favorite.show_all_btn");
      } else {
        return this.tn("favorite.show_favorite_btn");
      }
    },

    blackListBtnTitle() {
      if (this.isShowBlackList) {
        return this.tn("black.show_not_black_btn");
      } else if (this.isShowBlackList == false) {
        return this.tn("black.show_all_btn");
      } else {
        return this.tn("black.show_black_btn");
      }
    },

    isLoading: (vm) => !!Object.keys(vm.loaders).length,

    isFilterBadge() {
      const values = Object.values(this.filterData);
      const filters = values.filter((x) => x === 0 || `${x || ""}`.length);
      return filters.length;
    },
  },

  watch: {
    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },
  },

  mounted() {
    document.addEventListener("keyup", this.onKeyup);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.onKeyup);
  },

  methods: {
    ...mapActions("price-list", ["GET_API"]),

    ...mapActions("auth", ["GET_PERMISSIONS_LIST"]),

    async getEntries() {
      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      const params = { options: { ...this.options } };

      params.options.sortBy = this.$getSort(params.options.sortBy);
      params.options.sortDesc = this.$getSort(params.options.sortDesc, true);

      if (!params.options.sortBy) delete params.options.sortDesc;

      if (this.searchText) params.options.search = this.searchText;

      params.filterData = {
        ...this.filterData,
        isFavoriteProvider: this.isShowFavorite,
        isBlackListProvider: this.isShowBlackList,
      };
      if (this.isShowFavorite == undefined) delete params.filterData.isFavoriteProvider;
      if (this.isShowBlackList == undefined) delete params.filterData.isBlackListProvider;

      await this.GET_API(params);

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    onSearch: debounce(function () {
      this.options.page = 1;
      this.$nextTick(() => this.getEntries());
    }, 200),

    onClear() {
      this.searchText = null;
      this.$nextTick(() => this.getEntries());
    },

    onFilter(filterData) {
      this.filterData = filterData;
      this.$setFilterData(filterData);
      this.$nextTick(() => this.getEntries());
    },

    getPhones(x) {
      return `${x || ""}`
        .split(", ")
        .map((x, i) => (i == 0 ? "" : "<br />") + this.$tel(x))
        .join("");
    },

    onKeyup(e) {
      if (e.keyCode == 113) {
        this.$refs.searchBox?.focus();
        this.$nextTick(() => {
          const input = this.$refs.searchBox?.$el?.querySelector("input");
          input?.select();
        });
      }
    },

    onShowFavorite() {
      if (this.isShowFavorite) this.isShowFavorite = false;
      else if (this.isShowFavorite == false) this.isShowFavorite = undefined;
      else this.isShowFavorite = true;
      this.getEntries({ reset: true });
    },

    onShowBlackList() {
      if (this.isShowBlackList) this.isShowBlackList = false;
      else if (this.isShowBlackList == false) this.isShowBlackList = undefined;
      else this.isShowBlackList = true;
      this.getEntries({ reset: true });
    },

    async onShowCompare({ item }) {
      if (!this.GET_USER.isTrial) {
        this.$router.push(`/price-list/compare/${item.guid}`);
      } else {
        this.$store.dispatch("SET_LOADING", true);
        await this.GET_PERMISSIONS_LIST();
        this.$store.dispatch("SET_LOADING", false);
        this.selected = item;

        this.tryCount = this.$getObjectParam("CanPriceListCompare", this.GET_USER_PERMISSIONS);
        if (this.$number(this.tryCount)) {
          this.isPermissionAlert = true;
        } else {
          this.$store.dispatch("SET_SNACKBAR", {
            text: this.$t("permission_confirm_dialog.permission_alert"),
            color: "error",
          });
        }
      }
    },

    onPermission() {
      this.isPermissionAlert = false;
      this.$nextTick(() => this.$router.push(`/price-list/compare/${this.selected.guid}`));
    },

    onAdd({ item }) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isAdd = true));
    },

    onOpen() {
      window.open("https://youtu.be/dNSB6aZF12E", "_blank");
    },
  },
};
</script>

<template>
  <v-container fluid>
    <Add
      :value="selected"
      :priceListProviderGuid="selected.providerGuid"
      :percent="selected.discountPercent"
      :minAmountToPurchase="selected.discountMinAmountToPurchase"
      :minAmountToPurchaseWithDelivery="selected.discountMinAmountToPurchaseWithDelivery"
      v-if="isAdd"
      @saved="getEntries"
      @close="isAdd = false"
    />

    <TableFilter
      v-if="isFilter"
      :value="filterData"
      @close="isFilter = false"
      @apply="onFilter"
    />

    <PermissionConfirmDialog
      :text="$t('permission_confirm_dialog.function_permission_alert')"
      :try-count="tryCount"
      @close="isPermissionAlert = false"
      v-if="isPermissionAlert"
      @accept="onPermission"
    />

    <div class="d-flex align-center pt-2">
      <h3 v-text="tn('title')" />

      <div class="mx-1" />

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            size="30"
            color="success"
            v-bind="attrs"
            @click="onOpen"
            v-on="on"
          >
            mdi-chat-question-outline
          </v-icon>
        </template>
        <span> {{ $t("how_does_this_work") }} </span>
      </v-tooltip>

      <v-spacer />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="getEntries"
        :disabled="isLoading"
        :loading="isLoading"
        :title="tn('refresh_btn')"
      >
        <v-icon> mdi-sync </v-icon>
      </v-btn>

      <div class="mx-1" />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        :loading="isLoading"
        :title="favoriteBtnTitle"
        @click="onShowFavorite"
      >
        <v-icon v-if="isShowFavorite"> mdi-star </v-icon>
        <v-icon v-else-if="isShowFavorite == undefined"> mdi-star-half-full </v-icon>
        <v-icon v-else> mdi-star-outline </v-icon>
      </v-btn>

      <div class="mx-1" />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        :loading="isLoading"
        :title="blackListBtnTitle"
        @click="onShowBlackList"
      >
        <v-icon v-if="isShowBlackList"> mdi-file-cancel </v-icon>
        <v-icon v-else-if="isShowBlackList == undefined"> mdi-file-document-multiple-outline </v-icon>
        <v-icon v-else> mdi-file-cancel-outline </v-icon>
      </v-btn>

      <div class="mx-1" />

      <table-cog
        :headersData="tableHeaders"
        @apply="(v) => (filteredHeaders = $compareArray(tableHeaders, v, 'value'))"
        :cacheKey="'price-list-table-cog'"
      />

      <div class="mx-1" />

      <v-badge
        dot
        overlap
        color="error"
        :value="isFilterBadge"
      >
        <v-btn
          color="primary"
          min-width="40"
          width="40"
          height="40"
          min-height="40"
          outlined
          @click="isFilter = true"
          :title="tn('filter_btn')"
        >
          <v-icon> mdi-filter </v-icon>
        </v-btn>
      </v-badge>

      <div class="mx-1" />

      <v-text-field
        outlined
        hide-details
        dense
        append-icon="mdi-magnify"
        @click:append="onSearch"
        @keyup.enter="onSearch"
        style="max-width: 300px; width: 100%"
        :label="tn('search')"
        v-model="searchText"
        clearable
        ref="searchBox"
      />
    </div>

    <div class="mt-3" />

    <v-data-table
      v-if="filteredHeaders.length && tableHeight > 0"
      :headers="filteredHeaders"
      dense
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      fixed-header
      :options.sync="options"
      :server-items-length="entriesCount"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        itemsPerPageOptions: [25, 50],
        showCurrentPage: true,
        showFirstLastPage: true,
        itemsPerPageText: $t('table.per_page_text'),
      }"
      class="fixed-right"
      :height="tableHeight"
      v-resize-table="{ tableHeaders: filteredHeaders, callback: onUpdateTdSizes }"
    >
      <template v-slot:item="{ item, headers, index }">
        <tr
          class="cursor-pointer"
          @click="selected = { ...item }"
          :class="selected.guid == item.guid && 'tr-active'"
          @dblclick="$router.push(`/price-list/${item.guid}`)"
        >
          <template v-for="head in headers">
            <td
              v-if="head.value == 'number'"
              :key="head.value"
              class="px-0 text-center"
            >
              <span
                class="text-nowrap"
                v-text="$sum((options.page - 1) * options.itemsPerPage + (index + 1))"
              />
            </td>
            <td
              v-else-if="head.value == 'favoriteAndBlackList'"
              :key="head.value + 1"
              class="px-0 text-center"
            >
              <div class="d-flex align-center">
                <v-btn
                  :title="tn('table.actions.add')"
                  @click="onAdd({ item })"
                  icon
                  x-small
                  :color="item._discountColor ? 'primary' : null"
                >
                  <v-icon>mdi-book-plus</v-icon>
                </v-btn>

                <price-list-provider-favorite-btn
                  :ln="ln"
                  :item="item"
                />
                <price-list-black-btn
                  :ln="ln"
                  :item="item"
                />
              </div>
            </td>

            <td
              v-else-if="head.value == 'phone'"
              :key="head.value + 2"
              :style="{ width: tdSizes[head.value] || head.width, maxWidth: tdSizes[head.value] || head.width }"
            >
              <div class="d-flex text-nowrap">
                {{ head._filter(item[head.value], item) }}
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-1"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-more
                    </v-icon>
                  </template>
                  <v-card>
                    <div
                      class="pa-5"
                      v-html="getPhones(item[head.value])"
                    />
                  </v-card>
                </v-menu>
              </div>
            </td>

            <td
              v-else-if="head._filter"
              :key="head.value + 3"
              :style="{ width: tdSizes[head.value] || head.width, maxWidth: tdSizes[head.value] || head.width }"
            >
              <div
                class="text-nowrap"
                v-if="head._html"
                v-html="head._filter(item[head.value], item)"
              />
              <div
                v-else
                class="text-nowrap"
                v-text="head._filter(item[head.value], item)"
              />
            </td>

            <td
              v-else-if="head.value == 'provider'"
              :key="head.value + 4"
              :style="{ width: tdSizes[head.value] || head.width, maxWidth: tdSizes[head.value] || head.width }"
            >
              <div class="d-flex">
                <div class="tb-action-wrap-2">
                  <v-menu
                    offset-y
                    offset-x
                    nudge-left="5"
                    nudge-top="5"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        min-height="28"
                        height="28"
                        min-width="28"
                        width="28"
                        depressed
                        color="transparent"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon> mdi-menu </v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <template v-for="(actionItem, index) in actionsList">
                        <v-list-item
                          :key="index"
                          @click="actionItem.action(item)"
                        >
                          <v-list-item-title :class="actionItem.class">
                            {{ actionItem.name }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-divider
                          v-if="index < actionsList.length - 1"
                          :key="index + 'i'"
                        />
                      </template>
                    </v-list>
                  </v-menu>
                </div>
                {{ item.provider }}
              </div>
            </td>

            <td
              v-else-if="head.value == 'actions'"
              :key="head.value + 5"
              class="px-0"
              :style="{ width: head.width }"
            >
              <div class="d-flex justify-center tb-action-wrap">
                <router-link
                  :to="`/price-list/${item.guid}`"
                  class="px-2 text-nowrap"
                >
                  {{ tn("table.actions.show") }}
                </router-link>
                |
                <div
                  @click="onShowCompare({ item })"
                  class="px-2 text-nowrap"
                  style="color: #1976d2"
                >
                  {{ tn("table.actions.compare") }}
                </div>
              </div>
            </td>

            <td
              v-else
              :key="head.value + 6"
              :style="{ width: tdSizes[head.value] || head.width, maxWidth: tdSizes[head.value] || head.width }"
            >
              <div v-text="item[head.value]" />
            </td>
          </template>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<style lang="scss" scoped>
.tb-action-wrap {
  transform: none !important;
}

.tb-action-wrap-2 {
  padding-top: 2px;
}
</style>
