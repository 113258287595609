<script>
import ModalsTemplate from "@/components/modals-template.vue";
import tnVue from "@/mixins/tn.vue";
import { mapActions } from "vuex";

export default {
  components: { ModalsTemplate },

  props: {
    value: Object,
    priceListProviderGuid: String,
    percent: Number,
    minAmountToPurchase: Number,
    minAmountToPurchaseWithDelivery: Number,
  },

  data: (vm) => ({
    dialog: true,
    isLoading: false,

    formData: {
      percent: vm.percent || null,
      minAmountToPurchase: vm.minAmountToPurchase || 0,
      minAmountToPurchaseWithDelivery: vm.minAmountToPurchaseWithDelivery || 0,
    },
    mySellingPrice: vm.value?.guid ? localStorage.getItem(btoa(`markup/${vm.value.guid}`)) : null,

    ln: "my_discount.add",
  }),

  mixins: [tnVue],

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  methods: {
    ...mapActions("my-discount", ["ADD_API"]),

    async onSave() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const formData = { ...this.formData };
        formData.priceListProviderGuid = this.priceListProviderGuid;
        formData.percent = this.$number(this.formData.percent);

        if (this.value?.guid) {
          const key = btoa(`markup/${this.value.guid}`);
          if (this.$number(this.mySellingPrice) > 0) localStorage.setItem(key, this.mySellingPrice);
          else localStorage.removeItem(key);
        }

        const status = await this.ADD_API(formData);
        this.isLoading = false;
        if (status) {
          this.$emit("saved");
          this.dialog = false;
        }
      } else {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.$t("fill_required"),
          color: "info",
        });
      }
    },
  },
};
</script>

<template>
  <modals-template
    :width="500"
    :title="tn('title')"
    v-model="dialog"
  >
    <v-form
      :disabled="isLoading"
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      <v-text-field
        :label="tn('form.percent')"
        autofocus
        outlined
        @keyup.enter="onSave"
        dense
        hide-details
        v-model="formData.percent"
        v-inputmask="$masks.percent"
        required
        :rules="[$rules.required]"
      />

      <div class="mt-5" />

      <v-text-field
        :label="`${$t('my_markup')} (%)`"
        outlined
        hide-details
        dense
        @keyup.enter="onSave"
        clearable
        v-inputmask="$masks.numberMax({ max: 999, left: true })"
        v-model="mySellingPrice"
        @focus="$event.target.select()"
      />

      <div class="mt-5" />

      <v-text-field
        :label="tn('form.minAmountToPurchase')"
        outlined
        @keyup.enter="onSave"
        dense
        hide-details
        v-model="formData.minAmountToPurchase"
        v-inputmask="$masks.number"
      />

      <div class="mt-5" />

      <v-text-field
        :label="tn('form.minAmountToPurchaseWithDelivery')"
        outlined
        @keyup.enter="onSave"
        dense
        hide-details
        v-model="formData.minAmountToPurchaseWithDelivery"
        v-inputmask="$masks.number"
      />

      <v-btn
        height="45"
        :loading="isLoading"
        color="primary"
        @click="onSave"
        block
        elevation="0"
        class="mt-5 mb-1"
      >
        {{ tn("save_btn") }}
      </v-btn>
    </v-form>
  </modals-template>
</template>
