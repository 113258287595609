<script>
import { mapActions, mapGetters } from "vuex";

import tnVue from "@/mixins/tn.vue";
import TableResizeVue from "@/mixins/table-resize.vue";
import TableNavFunctionsVue from "@/mixins/table-nav-functions.vue";

import PriceListItems from "@/components/price-list-items/price-list-items.vue";

const urlGetFreshListNotFound = `BasketLineItem/GetFreshListNotFound`;

export default {
  props: {
    ln: String,
  },

  components: {
    PriceListItems,
  },

  data: () => ({
    uniqKey: "basket-fresh-not-found",
    isShowDelete: false,

    urlGetFreshListNotFound,
    isMyMarkup: false,
    markupText: null,
  }),

  mixins: [tnVue, TableResizeVue, TableNavFunctionsVue],

  computed: {
    size: (vm) => vm.$store.getters.GET_SIZE,

    ...mapGetters("basket", { basketItem: "GET_BASKET_BY_GUID" }),
    ...mapGetters("price-list-items", ["GET", "GET_COUNT", "GET_TOTALS"]),

    entries: (vm) => vm.GET(urlGetFreshListNotFound),
    entriesCount: (vm) => vm.GET_COUNT(urlGetFreshListNotFound),
    entriesTotals: (vm) => vm.GET_TOTALS(urlGetFreshListNotFound),

    tableHeight: (vm) => vm.size.height - 230,

    tableHeaders: (vm) =>
      [
        {
          text: "№",
          value: "number",
          width: 10,
          sortable: false,
          _resize: false,
          class: "disable-resize",
        },
        {
          text: vm.tn("table.productName"),
          value: "productName",
          width: vm.$dxcs.productName,
          _required: true,
        },
        {
          text: vm.tn("table.manufacturerName"),
          value: "manufacturerName",
          width: vm.$dxcs.manufacturer,
          _required: true,
        },
        {
          text: vm.tn("table.shelfLife"),
          value: "shelfLife",
          width: vm.$dxcs.date,
          _filter: (v) => vm.$date(v),
        },
        {
          text: vm.tn("table.priceTypeName"),
          value: "priceTypeName",
          width: vm.$dxcs.priceTypeName,
        },
        {
          text: vm.tn("table.amount"),
          value: "amount",
          _filter: (v) => vm.$sum(v),
          width: vm.$dxcs.amount,
          _align: "right",
          _required: true,
        },
        {
          text: vm.tn("table.discount_amount"),
          value: "amountDiscount",
          _filter: (v) => vm.$sum(v),
          width: vm.$dxcs.amount,
          _align: "right",
        },
        {
          text: vm.tn("table.my_selling_price"),
          value: "mySellingPrice",
          sortable: false,
          visible: vm.isMyMarkup,
          _filter: (v, item) => {
            const a = item.amountDiscount || item.amount;
            const b = localStorage.getItem(btoa(`markup/${item?.priceListGuid}`)) || vm.markupText;
            const diff = Math.round(+(a * (b / 100)).toFixed(2));
            const sellingPrice = Math.round(+(a + diff).toFixed(2));

            if (diff) {
              let vl = `<span class="green--text">(+${vm.$sum(diff)})</span> `;
              return `${vm.$sum(vl)} ${vm.$sum(sellingPrice)}`;
            }
            return `${vm.$sum(sellingPrice)}`;
          },
          width: vm.$dxcs.price,
          _align: "right",
        },
        {
          text: vm.tn("table.load"),
          value: "load",
        },
        {
          text: vm.tn("table.actions.count"),
          value: "actions",
          class: "disable-resize",
          sortable: false,
          _resize: false,
          width: 85,
        },
      ]
        .map((x) => {
          if (vm.tdSizes[x.value]) x.width = vm.tdSizes[x.value];
          return x;
        })
        .filter((e) => e.visible != false),

    basketGuid: (vm) => vm.$route.params.basketGuid,
  },

  async created() {
    await this.getBasketByGuidApi({ guid: this.basketGuid });
    if (!this.basketItem?.guid) this.$router.push("/basket");
  },

  methods: {
    ...mapActions("basket", {
      getBasketByGuidApi: "GET_BASKET_BY_GUID_API",
    }),
    ...mapActions("price-list-items", ["GET_API"]),

    getEntries(params) {
      params.options.basketGuid = this.basketGuid;
      return this.GET_API(params);
    },

    onRefresh() {
      this.$refs?.table1?.getEntries();
    },
  },
};
</script>

<template>
  <price-list-items
    :GET_API="getEntries"
    :entries="entries"
    @markup="(v) => (markupText = v)"
    @isMyMarkup="(v) => (isMyMarkup = v)"
    :entriesCount="entriesCount"
    :tableHeaders="tableHeaders"
    :ln="ln"
    :tableHeight="tableHeight"
    :uniqKey="uniqKey"
    :url="urlGetFreshListNotFound"
    isShowTotals
    ref="table1"
    :searchBoxHotKey="113"
    disableCount
    :v-resize-table="{ tableHeaders: tableHeaders, callback: onUpdateTdSizes }"
  >
    <template #title>
      <div>
        <h3>{{ basketItem.providerName }}</h3>
        <small>
          <b> {{ tn("header.price_type") }}: </b>
          {{ basketItem.priceTypeName }};
        </small>
      </div>
    </template>

    <template #actions-prepend>
      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="$emit('delete')"
        :title="tn('delete_btn')"
      >
        <v-icon> mdi-delete-outline </v-icon>
      </v-btn>

      <div class="px-1" />

      <v-btn
        color="primary"
        min-width="40"
        width="40"
        height="40"
        min-height="40"
        outlined
        @click="$emit('changeFresh')"
        :title="tn('change_with_fresh_list_btn')"
      >
        <v-icon> mdi-cart-arrow-down </v-icon>
      </v-btn>
      <div class="px-1" />
    </template>

    <template #body-append="bodyProps">
      <td
        v-for="(header, i) in bodyProps.headers"
        :key="i"
      >
        <div
          v-if="header.value == 'number'"
          class="text-nowrap text-center"
        >
          {{ entriesCount | sum }}
        </div>

        <div
          v-else-if="header.value == 'amount'"
          class="text-nowrap text-right"
        >
          {{ entriesTotals.totalAmount | sum }}
        </div>

        <div
          v-else-if="header.value == 'actions'"
          class="text-nowrap text-right"
        >
          {{ entriesTotals.totalQuantity | sum }}
        </div>

        <div v-else />
      </td>
    </template>
  </price-list-items>
</template>
